<template>
  <div v-if="show">
    <div class="body">
      <div class="head">
        <div style="font-size: 1.23vw">弱有所扶</div>
        <div style="font-size: 1.23vw">牵头单位：路桥区民政局</div>
      </div>
      <div class="btn">
        <div
          v-for="(item, index) in btnItem"
          :key="index"
          :class="navindex == index ? 'btn-box active' : 'btn-box'"
          @click="nav(index)"
        >
          <div>{{ item.text }}</div>
        </div>
        <!-- <div
          @click="nav(0)"
          :class="navindex == 0 ? 'btn-box active' : 'btn-box'"
        >
          <div>核心业务</div>
        </div>
        <div
          @click="nav(1)"
          :class="navindex == 1 ? 'btn-box active' : 'btn-box'"
        >
          <div>资源分布</div>
        </div>
        <el-tooltip
          class="item btn-box"
          effect="light"
          content="建设中"
          placement="top"
        >
          <el-button>
            <div style="color: #dbdbdb">预警检测</div>
          </el-button>
        </el-tooltip>
        <div
          @click="nav(3)"
          :class="navindex == 3 ? 'btn-box active' : 'btn-box'"
        >
          <div>改革创新</div>
        </div> -->
      </div>

      <!-- 救助总人数 -->
      <!-- 核心业务 -->
      <div v-show="navindex == 0" style="display: flex; flex-wrap: wrap; justify-content: space-between">
        
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>救助情况</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in rescue"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
            <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>
        <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="4"
            small
            @current-change="change"
          >
          </el-pagination>
        </div> -->
      </div>

      <!-- <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>救助类型</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in rescueType"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
             <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>
      </div> -->
       
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>低保类型</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in allowance"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
             <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>
      </div>

      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>贫困原因</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in poor"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
             <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>
      </div>

       <el-dialog
        :title="title"
        :visible.sync="dialogMedal"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
        class="new_dialog"
      >
          <div style="color:#000;font-size:14px">{{oText}}</div>
          <div style="color:#1492ff;font-size:12px"><span >{{totalTitle}}</span></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart" style="width: 100%; height: 303px"></div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible2"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="charts" style="width: 100%; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible3"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chartss" style="width: 100%; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible4"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chartsss" style="width: 100%; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible5"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chartssss" style="width: 100%; height: 303px"></div>
      </el-dialog>


      <el-dialog
        :title="title"
        :visible.sync="dialogVisible6"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart3" style="width: 100%; height: 303px"></div>
      </el-dialog>

      <!-- <el-dialog
        :title="title"
        :visible.sync="dialogVisible6"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialogVisible5_box">
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">边缘户</div>
            <div class="dialogVisible5_t2">总个数442个</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">低保户</div>
            <div class="dialogVisible5_t2">总个数2814个</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">特困户</div>
            <div class="dialogVisible5_t2">总个数179个</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">医疗</div>
            <div class="dialogVisible5_t2">总个数114个</div>
          </div>
        </div>
      </el-dialog> -->

      </div>

      <!-- 资源分布 -->
      <div v-show="navindex == 1">
        <div class="amap-page-container">
          <el-amap
            vid="amapDemo"
            mapStyle="normal"
            :center="center"
            :zoom="zoom"
            :plugin="plugin"
            class="amap-demo"
            :events="events"
            pitch-enable="false"
          >
            <el-amap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :events="marker.events"
              :position="marker.position"
            />
          </el-amap>
        </div>
      </div>
      <!-- 预警检测 -->
      <div v-show="navindex == 2" style="display: flex; flex-wrap: wrap; justify-content: space-between">

        <div class="culture" style="height:400px">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>低保</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div ref="chart1" style="width:40vw;height:300px;margin: 0 auto;margin-top:16px"></div>
      </div>

      <div class="culture" style="height:400px">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>特困</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div ref="chart2" style="width:40vw;height:300px;margin: 0 auto;margin-top:16px"></div>
      </div>
        <!-- <div class="culture">
          <div class="title">
            <div>求助热线</div>
            <div class="title-right">
       
              <el-tooltip class="item" effect="light" placement="top">
                <template #content>
                  <div>数据范围：{{ oldtime }}</div>
                  <div style="margin: 5px 0">更新时间：{{ systemTime }}</div>
                  <div>更新频率：每季度</div>
                </template>
                <el-button><img src="../assets/闹钟.png" /></el-button>
              </el-tooltip>
            </div>
          </div>
          <div class="hpc">
            <div class="hpc1">
              <div style="background-color: #f2f2f2; border: none">
                单位名称
              </div>
              <div>路桥区路桥街道便民服务中心综合窗口（路桥南官大道399号）</div>
              <div>
                路桥区路南街道办事处便民服务中心（吉利大道方林小区西大门口）
              </div>
              <div>路桥区路北街道便民服务中心2号窗口(腾达路1999号）</div>
              <div>路桥区螺洋街道便民服务中心4号窗口（螺洋街道青春路17号）</div>
              <div>
                路桥区桐屿街道便民服务中心7号窗口（桐屿街道屿城路482号）
              </div>
              <div>路桥区峰江街道便民服务中心综合窗口（峰江街道银水路199号</div>
              <div>路桥区新桥镇便民服务中心综合窗口（新桥镇人民路6号）</div>
              <div>路桥区横街镇便民服务中心3、4、5窗口（横街镇新兴路60号）</div>
              <div>
                路桥区蓬街镇便民服务中心5号窗口（蓬街镇青龙东路二区56-1号）
              </div>
              <div>路桥区金清镇便民服务中心1号窗口（金清大道东1号）</div>
            </div>
            <div class="hpc2">
              <div style="background-color: #f2f2f2; border: none">
                单位名称
              </div>
              <div>82442714</div>
              <div>82435572</div>
              <div>82527019</div>
              <div>82354551</div>
              <div>82331117</div>
              <div>82688213</div>
              <div>82660059</div>
              <div>82653532</div>
              <div>82724289</div>
              <div>82883301</div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- 改革创新 -->
      <div v-show="navindex == 3">
        <div class="culture2">
          <div class="title2">
            <div>改革创新</div>
            <div class="title-right2">
              <!-- <div class="title-right-item">
              <div>责任单位：路桥区民政局</div>
            </div> -->
              <el-tooltip class="item2" effect="light" placement="top" style="background:#e7f4fe;border:0 solid #e7f4fe">
                <template #content>
                  <div>数据范围：{{ oldtime }}</div>
                  <div style="margin: 5px 0">更新时间：{{ systemTime }}</div>
                  <div>更新频率：每季度</div>
                </template>
                <el-button><img src="../assets/闹钟.png" /></el-button>
              </el-tooltip>
            </div>
          </div>
          <div class="gg">
            <div class="ggt">亮点创新</div>
            <div class="ggc">
              为深入贯彻落实习近平总书记关于民政工作的重要指示精神，推进公共服务领域“最多跑一次”改革，构建“资源统筹、部门联动、社会参与、数据共享”的大救助体系，全面提升群众的获得感和幸福感。
              统筹“8+X”社会救助资源，通过建立常态化联系探访制度，为特困、低保、低边等三类救助对象提供临时救助、医疗、教育、住房、自然灾害、就业、残疾人补贴、水、天然气、有线电视、电力等14个救助联办事项服务及社会组织参与的帮扶救助服务。打造大救助“一件事”“一站式”服务模式，建立“一窗受理、信息共享、分工负责、部门联动”的工作机制。
            </div>
          </div>
          <div class="gg">
            <div class="ggt">改革政策</div>
            <div class="ggc">
              关于印发《路桥区大救助“一件事”最多跑一次改革实施方案》的通知（路改办发〔2020〕8号）。
            </div>
          </div>
          <div class="gg">
            <div class="ggt">流程展示</div>
            <div class="ggc">
              大救助“一件事”办事流程
              <img style="width: 100%" src="../assets/1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script>
import dd from "gdt-jsapi";
import * as echarts from "echarts";
import "echarts-liquidfill";
export default {
  components: {},
  data() {
    return {
      totalTitle:"",
      title:"",
      oText:"",
      dialogMedal: false,
      dialogVisible:false,
      dialogVisible2:false,
      dialogVisible3:false,
      dialogVisible4:false,
      dialogVisible5:false,
      dialogVisible6:false,
      btnItem: [
        { text: "核心业务", index: 0 },
        { text: "资源分布", index: 1 },
        { text: "预警监测", index: 2 },
        { text: "改革创新", index: 3 },
      ],
      addlist: [
        { longitude: 121.367016, latitude: 28.569466 },
        { longitude: 121.39719, latitude: 28.559131 },
        { longitude: 121.354641, latitude: 28.590941 },
        { longitude: 121.329551, latitude: 28.55782 },
        { longitude: 121.327084, latitude: 28.591034 },
        { longitude: 121.378598, latitude: 28.53894 },
        { longitude: 121.419162, latitude: 28.520105 },
        { longitude: 121.442872, latitude: 28.532947 },
        { longitude: 121.484616, latitude: 28.552001 },
        { longitude: 121.51896, latitude: 28.507596 },
      ],
      navindex: 0,
      show: true,
      showtoast: false,
      systemTime: "",
      oldtime: "",
      time1:"",
      time2:"",
      //地图
      makerlist: [],
      zoom: 12,
      center: [121.374146, 28.568495],
      markers: [],
      events: {
        click(e) {
          // const { lng, lat } = e.lnglat;
          // self.lng = lng;
          // self.lat = lat;
        },
      },
      plugin: [
        {
          pName: "DistrictSearch",
          events: {
            init(o) {},
          },
        },

        {
          // 定位
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng;
                  // 设置维度
                  self.lat = result.position.lat;
                  // 设置坐标
                  self.center = [self.lng, self.lat];
                  self.markers.push([self.lng, self.lat]);
                  // load
                  self.loaded = true;
                  // 页面渲染好后
                  self.$nextTick();
                }
              });
            },
          },
        },
      ],
      selPoint: "",
      rescue: [
        {id:6, text: "救助总人数",num: "3549",company: "个",txt:"当年累计3549个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数3549个" },
        {id:1,text: "救助类型占比",num: "3745",company: "人",txt:"当年累计3745人  同比",trend:{type:1,text:"↑ 0%"}, btn: "看趋势"},
        // { text: "边缘户",num: "442",company: "个",txt:"当年累计442个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数442个" },
        // { text: "低保户",num: "2814",company: "个",txt:"当年累计2814个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数2814个" },
        // { text: "特困户",num: "179",company: "个",txt:"当年累计179个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数179个" },
        // { text: "医疗",num: "114",company: "个",txt:"当年累计114个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数114个" },
      ],
      // rescue2: [
      //   { text: "边缘户",num: "442",company: "个",txt:"当年累计442个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数442个" },
      //   { text: "低保户",num: "2814",company: "个",txt:"当年累计2814个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数2814个" },
      //   { text: "特困户",num: "179",company: "个",txt:"当年累计179个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数179个" },
      // ],
      rescueType: [
        {id:1,text: "救助类型占比",num: "3745",company: "人",txt:"当年累计3745人  同比",trend:{type:1,text:"↑ 0%"}, btn: "看趋势"},
      ],
      allowance: [
        {id:2,text: "低保类型",num: "2814",company: "人",txt:"当年累计2814人  同比",trend:{type:1,text:"↑ 0%"}, btn: "看趋势" },
      ], 
      poor: [
        {id:3,text: "边缘户近5年致贫原因",num: "421",company: "人",txt:"当年累计421人  同比",trend:{type:1,text:"↑ 5%"}, btn: "看趋势"},
        {id:4, text: "低保户近5年致贫原因",num: "2809",company: "人",txt:"当年累计2809人  同比",trend:{type:1,text:"↑ 5%"}, btn: "看趋势"},
        {id:5, text: "特困户近5年致贫原因",num: "179",company: "人",txt:"当年累计179人  同比",trend:{type:1,text:"↑ 7%"}, btn: "看趋势"},
      ],
      show: true
    };
  },
  created() {
    const isZzd = JSON.stringify(navigator.userAgent).indexOf("TaurusApp") > -1;
    if (isZzd) {
      this.listenResize();
    } else {
      window.location.href =
        "https://dgov-integrate.jinhua.gov.cn:5443/szjh-gov-pc/#/404";
    }
    let nowDate = new Date();
    let date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate(),
    };
    this.systemTime = date.year + "年" + date.month + "月" + date.date + "日";
    // this.oldtime = date.year + "年" + "1" + "-" + date.month + "月";
    this.oldtime = "截止2022年1月";
  },
  mounted() {
    // this.init();
    // this.inits();
    // this.initss();
    // this.initsss();
    // this.initssss();
    // this.init1();
    // this.init2();
     window.onresize = () => {
      return (() => {
        location.reload();
      })();
    };
  },
  watch: {
      show(e) {
      if (e) {
        setTimeout(() => {
          this.init();
        }, 1);
      }
    },
    dialogVisible(e) {
      if (e) {
        setTimeout(() => {
          this.init();
        }, 1);
      }
    },
    dialogVisible2(e) {
      if (e) {
        setTimeout(() => {
          this.inits();
        }, 1);
      }
    },
    dialogVisible3(e) {
      if (e) {
        setTimeout(() => {
          this.initss();
        }, 1);
      }
    },
    dialogVisible4(e) {
      if (e) {
        setTimeout(() => {
          this.initsss();
        }, 1);
      }
    },
    dialogVisible5(e) {
      if (e) {
        setTimeout(() => {
          this.initssss();
        }, 1);
      }
    },
    dialogVisible6(e) {
      if (e) {
        setTimeout(() => {
          this.init3();
        }, 1);
      }
    },
  },
  methods: {
    listenResize() {
      setTimeout(() => {
        window.onresize = () => {
          this.init();
          this.inits();
          this.initss();
          this.initsss();
          this.initssss();
          this.init1();
          this.init2();
          this.init3();

        };
      }, 0);
    },
    mouseenter() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      // this.time1 = "数据范围：" + yy + "年" + "1月-" + mm + "月";
      this.time1 = "数据范围：截止2022年1月";
      this.time2 = "更新时间：" + yy + "年" + mm + "月" + dd + "日";
    },
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
    showwindow() {
      this.showtoast = true;
    },
    hidewindow() {
      this.showtoast = false;
    },
    nav(index) {
      this.navindex = index;

      if (this.navindex == 1) {
        this.addlist.forEach((item, index) => {
          var data = {
            position: item.longitude + "," + item.latitude,
          };
          // data.url = require("../assets/闹钟.png");
          this.makerlist.push(data);
        });
        const markers = [];
        this.makerlist.forEach((item, index) => {
          markers.push({
            position: item.position.split(","),
            // icon: item.url,
          });
        });
        //  加点
        this.markers = markers;
      }
      if (this.navindex == 2) {
        this.init1();
        this.init2();
      }
    },
    // 表1
    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        legend: {
          top: "center",
          right: "5%",
          orient: "vertical",
          padding: [0.5, 0.5, 0.5, 0.5],
          textStyle: { fontSize: 14 },
        },
        grid: {
          top: "middle",
          left: "center",
        },
        color: ["#1492ff", "#ffd800", "#16a085", "#f39c11", "#ca2c68"],
        series: [
          {
            type: "pie",
            data: [
              { value: 442, name: "边缘户" },
              { value: 2814, name: "低保" },
              { value: 166, name: "临时" },
              { value: 179, name: "特困" },
              { value: 144, name: "医疗" },
            ],
            radius: ["40%", "70%"],
            label: {
              formatter: "{@c}人 ({d}%)",
              fontSize: 16,
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    // 表2
    inits() {
      this.chart = echarts.init(this.$refs.charts);
      let option = {
        legend: {
          top: "center",
          right: "1%",
          orient: "vertical",
          padding: [0.5, 0.5, 0.5, 0.5],
          itemGap: 5,
          textStyle: { fontSize: 14 },
        },
        color: ["#1492ff", "#ffd800", "#16a085", "#f39c11"],
        grid: {
          top: "middle",
          right: "center",
        },
        series: [
          {
            type: "pie",
            data: [
              { value: 866, name: "残疾人单列户施保" },
              { value: 1920, name: "收入型低保" },
              { value: 23, name: "支出型贫困" },
              { value: 5, name: "其他" },
            ],
            // radius: ["40%", "70%"],
            label: {
              formatter: "{@c}人 ({d}%)",
              fontSize: 16,
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    //表3
    initss() {
      this.chart = echarts.init(this.$refs.chartss);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2018", "2019", "2020"],
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "25%",
          left: "15%",
          bottom: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          top: "1vh",
          left: "center",
          data: ["因病", "因残", "因灾", "失业", "缺乏劳动力", "其他"],
        },
        color: [
          "#56abfb",
          "#fba75a",
          "#7c2fa2",
          "#fede47",
          "#8bd5aa",
          "#cd4390",
        ],
        series: [
          {
            name: "因病",
            type: "line",
            stack: "总量",
            data: [207, 217, 225],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#56abfb",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因残",
            type: "line",
            stack: "总量",
            data: [54, 56, 64],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fba75a",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因灾",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7c2fa2",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "失业",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fede47",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "缺乏劳动力",
            type: "line",
            stack: "总量",
            data: [16, 25, 28],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd5aa",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "其他",
            type: "line",
            stack: "总量",
            data: [100, 102, 104],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cd4390",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initsss() {
      this.chart = echarts.init(this.$refs.chartsss);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2019", "2020", "2021"],
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "25%",
          left: "15%",
          bottom: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          top: "1vh",
          data: ["因病", "因残", "因灾", "失业", "缺乏劳动力", "其他"],
        },
        color: [
          "#56abfb",
          "#fba75a",
          "#7c2fa2",
          "#fede47",
          "#8bd5aa",
          "#cd4390",
        ],
        series: [
          {
            name: "因病",
            type: "line",
            stack: "总量",
            data: [596, 669, 711],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#56abfb",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因残",
            type: "line",
            stack: "总量",
            data: [1302, 1396, 1457],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fba75a",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因灾",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7c2fa2",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "失业",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fede47",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "缺乏劳动力",
            type: "line",
            stack: "总量",
            data: [74, 98, 119],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd5aa",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "其他",
            type: "line",
            stack: "总量",
            data: [481, 510, 522],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cd4390",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initssss() {
      this.chart = echarts.init(this.$refs.chartssss);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2017", "2018", "2019", "2020", "2021"],
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "25%",
          left: "15%",
          bottom: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          top: "1vh",
          data: ["因病", "因残", "因灾", "失业", "缺乏劳动力", "其他"],
        },
        color: [
          "#56abfb",
          "#fba75a",
          "#7c2fa2",
          "#fede47",
          "#8bd5aa",
          "#cd4390",
        ],
        series: [
          {
            name: "因病",
            type: "line",
            stack: "总量",
            data: [2, 7, 9, 11, 14],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#56abfb",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因残",
            type: "line",
            stack: "总量",
            data: [0, 0, 3, 5, 9],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fba75a",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因灾",
            type: "line",
            stack: "总量",
            data: [0, 0, 0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7c2fa2",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "失业",
            type: "line",
            stack: "总量",
            data: [0, 0, 0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fede47",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "缺乏劳动力",
            type: "line",
            stack: "总量",
            data: [1, 6, 10, 13, 17],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd5aa",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "其他",
            type: "line",
            stack: "总量",
            data: [130, 130, 130, 137, 139],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cd4390",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init1() {
      this.chart = echarts.init(this.$refs.chart1);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2019", "2020", "2021"],
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "25%",
          left: "1%",
          bottom: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          top: "1vh",
          data: ["因病", "因残", "因灾", "失业", "缺乏劳动力", "其他"],
        },
        color: [
          "#56abfb",
          "#fba75a",
          "#7c2fa2",
          "#fede47",
          "#8bd5aa",
          "#cd4390",
        ],
        series: [
          {
            name: "因病",
            type: "line",
            stack: "总量",
            data: [596, 669, 711],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#56abfb",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因残",
            type: "line",
            stack: "总量",
            data: [1302, 1396, 1457],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fba75a",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因灾",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7c2fa2",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "失业",
            type: "line",
            stack: "总量",
            data: [0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fede47",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "缺乏劳动力",
            type: "line",
            stack: "总量",
            data: [74, 98, 119],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd5aa",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "其他",
            type: "line",
            stack: "总量",
            data: [481, 510, 522],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cd4390",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init2() {
      this.chart = echarts.init(this.$refs.chart2);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2017", "2018", "2019", "2020", "2021"],
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        yAxis: {
          type: "value",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "25%",
          left: "1%",
          bottom: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          top: "1vh",
          data: ["因病", "因残", "因灾", "失业", "缺乏劳动力", "其他"],
        },
        color: [
          "#56abfb",
          "#fba75a",
          "#7c2fa2",
          "#fede47",
          "#8bd5aa",
          "#cd4390",
        ],
        series: [
          {
            name: "因病",
            type: "line",
            stack: "总量",
            data: [2, 7, 9, 11, 14],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#56abfb",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因残",
            type: "line",
            stack: "总量",
            data: [0, 0, 3, 5, 9],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fba75a",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "因灾",
            type: "line",
            stack: "总量",
            data: [0, 0, 0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7c2fa2",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "失业",
            type: "line",
            stack: "总量",
            data: [0, 0, 0, 0, 0],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fede47",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "缺乏劳动力",
            type: "line",
            stack: "总量",
            data: [1, 6, 10, 13, 17],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8bd5aa",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
          {
            name: "其他",
            type: "line",
            stack: "总量",
            data: [130, 130, 130, 137, 139],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cd4390",
                },
                {
                  offset: 1,
                  color: "#ECF6FF",
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init3() {
      this.chart = echarts.init(this.$refs.chart3);
      let option = {
        legend: {
          top: "center",
          right: "1%",
          orient: "vertical",
          padding: [0.5, 0.5, 0.5, 0.5],
          itemGap: 5,
          textStyle: { fontSize: 14 },
        },
        color: ["#1492ff", "#ffd800", "#16a085", "#f39c11"],
        grid: {
          top: "middle",
          right: "center",
        },
        series: [
          {
            type: "pie",
            data: [
              { value: 442, name: "边缘户" },
              { value: 2814, name: "低保户" },
              { value: 179, name: "特困户" },
              { value: 114, name: "医疗" },
            ],
            // radius: ["40%", "70%"],
            label: {
              formatter: "{@c}人 ({d}%)",
              fontSize: 16,
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    change(e) {
      if (e == 1) {
        this.rescue2 = this.rescue.slice(0, 3);
      } else if (e == 2) {
        this.rescue2 = this.rescue.slice(3, 6);
      } 
    },
    btn(e, i) {
      this.title = e;
      if(i == 1){
        this.dialogVisible = true;
      } else if(i == 2){
        this.dialogVisible2 = true;
      } else if(i == 3){      
        this.dialogVisible3 = true;
      } else if(i == 4){
        this.dialogVisible4 = true;
      } else if(i == 5){
        this.dialogVisible4 = true;
      } else if(i == 6){
        this.dialogVisible6 = true;
      }
    },
    btn2(e,v,t,i) {
      this.dialogMedal = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
  },
};
</script>

<style lang="less">
.gg {
  padding: 0.644vw;
  margin-bottom: 1.5vw;
}
.ggt {
  font-size: 1.2vw;
  font-weight: 900;
  color: #058aff;
  margin-bottom: 1.5vw;
}
.ggc {
  font-size: 1.1vw;
  text-indent: 2em;
}
.hpc {
  margin: 0.644vw;
  display: flex;
  align-items: center;
  font-size: 1.094vw;
}
.hpc1,
.hpc2 {
  width: 50%;
}
.hpc1 div,
.hpc2 div {
  height: 2.869vw;
  line-height: 2.869vw;
  border-bottom: 1px solid #e6e6e6;
}
.hpc1 div {
  border-left: 1px solid #e6e6e6;
  padding-left: 2%;
}
.hpc2 div {
  border-right: 1px solid #e6e6e6;
}
.el-vue-amap-container {
  height: calc(100vh) !important;
}
.amap-page-container {
  position: relative;
}
.el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: inherit;
  border: none;
  // color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  /* padding: 12px 20px; */
  font-size: 14px;
  /* border-radius: 4px; */
}
.body {
  padding: 0.644vw 0.644vw;
  .head {
    width: 100%;
    height: 3.861vw;
    background-image: linear-gradient(to right, #3fa6fe, #0085f8);
    display: flex;
    justify-content: space-between;
    border-radius: 0.515vw;
    align-items: center;
    color: #fff;
    padding: 0 1.287vw;
    font-size: 0.901vw;
  }
  .btn {
    width: 100%;
    height: 2.896vw;
    margin-top: 0.644vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 0.515vw;
    .btn-box {
      cursor: pointer;
      border-radius: 0.515vw;
      font-size: 1.094vw;
      width: 98.713vw/4;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active {
      border: 0.064vw solid #6cbbff;
      color: #6cbbff;
      background-color: #fff;
    }
    .showtoast:hover {
      color: #fff;
    }
  }
  .culture2 {
    margin-top: 0.644vw;
    border-radius: 0.515vw;
    overflow: hidden;
    border: 0.064vw solid #1191ff;
    .title2 {
      width: 100%;
      height: 3.09vw;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.931vw 0 0.644vw;
      div {
        font-size: 1.2vw;
        font-weight: 900;
        padding-left: 1.287vw;
        border-left: 0.322vw solid #1191ff;
      }
      img {
        width: 1.287vw;
      }
    }
    .title-right2 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #1492ff;
      border: none !important;
      padding: 0 !important;
    }
    .title-right-item2 {
      padding: 0 !important;
      border: none !important;
      margin-right: 15px;
      div {
        padding: 0 !important;
        border: none !important;
        // font-size: 12px !important;
      }
    }
    .common2 {
      width: 100%;
      padding: 1.416vw 0;
      display: flex;
      .left {
        height: 6.542vw;
        background-color: #f7f7f7;
        margin-left: 2%;
        //  margin-left: 1.287vw;
        // padding: 1.287vw 0;
        // width: 18.662vw;
        width: 22.5%;
        .down {
          display: flex;
          justify-content: center;
          align-items: center;
          .index {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 0.644vw;
            .num {
              margin-bottom: 0.644vw;
              color: #6ab2fb;
              font-size: 1.709vw;
            }
            .item {
              font-size: 1.33vw;
            }
          }
        }
      }
    }
  }
  .echart-page {
    display: flex;
    align-items: center;
  }
  .art {
    margin-top: 0.644vw;
    border-radius: 0.515vw;
    overflow: hidden;
    border: 0.064vw solid #1191ff;
    width: 49.5%;
    margin-right: 1%;
    .title {
      width: 100%;
      height: 3.09vw;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.931vw 0 0.644vw;
      div {
        font-size: 1.2vw;
        font-weight: 900;
        padding-left: 1.287vw;
        border-left: 0.322vw solid #1191ff;
      }
      img {
        width: 1.287vw;
      }
    }
    .commonn {
      width: 100%;
      padding: 1.416vw;
      display: flex;
      height: 42vh;
    }
  }
  .art2 {
    margin-top: 0.644vw;
    border-radius: 0.515vw;
    overflow: hidden;
    border: 0.064vw solid #1191ff;
    width: 32.666%;
    margin-right: 1%;
    .title {
      width: 100%;
      height: 3.09vw;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.931vw 0 0.644vw;
      div {
        font-size: 1.2vw;
        font-weight: 900;
        padding-left: 1.287vw;
        border-left: 0.322vw solid #1191ff;
      }
      img {
        width: 1.287vw;
      }
    }
    .commonn {
      width: 100%;
      display: flex;
      height: 42vh;
    }
  }
}



.culture {
    width: 49%;
    height: 474px;
    border: 1px solid #9dd2ff;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    .title {
      width: 100%;
      height: 40px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 18.6px;
      .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: 4px;
          height: 20px;
          background: #1492ff;
          border-radius: 2px;
          margin-left: 12px;
        }
        div {
          font-size: 20px;
          margin-left: 8px;
          font-family: Helvetica;
          font-size: 20px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      img {
        width: 19.948px;
      }
    }
    .common {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .left {
        height: 199.485px;
        background-color: #f7f7f7;
        margin-left: 19.948px;
        padding: 0 19.948px;
        .up {
          width: 100%;
          height: 49.879px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1.008px solid #ccc;
          img {
            width: 19.948px;
            height: 19.948px;
          }
          div {
            font-size: 19.964px;
            margin-left: 4.991px;
          }
        }
        .down {
          width: 100%;
          height: 139.639px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .index {
            display: flex;
            flex-direction: column;
            align-items: center;
            .num {
              margin-bottom: 19.948px;
              color: #6ab2fb;
              font-size: 29.931px;
            }
            .item {
              font-size: 17.965px;
            }
          }
        }
      }
    }
    .cultureItem {
      width: calc(100% - 32px);
      height: 118px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.05);
      border-radius: 8px;
      margin: 11px 16px 11px;
      padding: 16px;
      span:nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 500;
      }
      .span {
        width: 78px;
        height: 26px;
        float: right;
        background: #ffffff;
        border: 0.5px solid #1492ff;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 26px;
        .iconfont {
          color: #1492ff;
        }
      }
      .span:hover {
        cursor: pointer;
      }
      div:nth-child(2) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .company {
          margin-left: 4px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .el-pagination {
      button {
        display: none;
      }
      .number {
        color: #59aaf5;
        background: #edf4ff;
      }
      .active {
        color: #ffffff;
        background: #1492ff;
      }
    }
  }
  .culture:nth-child(1){
    margin-top: 16px;
  }
  .culture:nth-child(2){
    margin-top: 16px;
  }
  .art {
    margin-top: 9.982px;
    border-radius: 7.982px;
    overflow: hidden;
    border: 0.992px solid #1191ff;
    .title {
      width: 100%;
      height: 47.895px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 29.931px 0 9.982px;
      div {
        font-size: 15.965px;
        font-weight: 900;
        padding-left: 19.948px;
        border-left: 4.991px solid #1191ff;
      }
      img {
        width: 19.948px;
      }
    }
    .commonn {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .data {
        width: 488.746px;
        height: 219.434px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .dataItem {
          display: flex;
          justify-content: space-between;
          padding: 0 42.889px 0 19.948px;
          .left {
            display: flex;
            justify-content: center;
            align-items: center;
            .spot {
              width: 9.982px;
              height: 9.982px;
              border-radius: 50%;
              background-color: #058cfe;
              margin-right: 14.957px;
            }
            .item {
              font-size: 15.965px;
            }
          }
          .num {
            color: #058cfe;
            font-size: 15.965px;
          }
        }
      }
    }
  }

.el-dialog__wrapper {
  width: 100%;
  .el-dialog__header {
    .el-dialog__title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
      border-left: 4px solid #1492ff !important;
      border-radius: 2px;
      padding-left: 8px;
    }
    .el-dialog__headerbtn {
      width: 26px;
      height: 26px;
      // background: #1492ff;
      border-radius: 50%;
      i {
        color: #000;
      }
    }
 

  }
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px;
}
.v-modal {
  width: 100% !important;
}
.el-dialog__body {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.dialogVisible5_title {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.dialogVisible5_box {
  width: 100%;
}
.dialogVisible5_t1 {
  flex: 5;
  color:#000;
  font-size:14px;

}
.dialogVisible5_t2 {
  flex: 1;
  color:#1492ff;
  font-size:12px
}
</style>
