import axios from 'axios'
import Qs from 'qs'

axios.defaults.withCredentials = true

axios.defaults.crossDomain = true

// const base = process.env.NODE_ENV === 'production' ? 'cnlqxy.com' : 'zfw.china1d.com/app/'

// export const baseurl = 'http://' + base
// export const baseurl = process.env.NODE_ENV === 'production' ? 'https://' + base : 'http://' + base
export const baseurl='https://lqszfz.luqiao.gov.cn/app/'
// export const wsurl = 'ws://' + base
// export const wsurl = process.env.NODE_ENV === 'production' ? 'wss://' + base : 'ws://' + base

axios.defaults.baseURL = baseurl

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.defaults.transformRequest = [
  function (data, config) {
    // 对 data 进行任意转换处理
    if (config['Content-Type'] === 'multipart/form-data') {
      return data
    }
    return Qs.stringify(data)
  },
]

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((resp) => {
        resolve(resp.data)
      })
      .catch((err) => {
        reject(err.response.data)
      })
  })
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((resp) => {
        resolve(resp)
      })
      .catch((err) => {
        reject(err.response.data)
      })
  })
}

const upvideoconfig = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export function uploadfile(url, data) {
  return new Promise((resolve, reject) => {
    upvideoconfig
      .post(url, data)
      .then((resp) => {
        resolve(resp)
      })
      .catch((err) => {
        reject(err.response.data)
      })
  })
}
export function del(table, id) {
  return post('/api/common/delete_y', {
    table: table,
    id: id
  })
}