import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAMap from 'vue-amap';
import "./utils/ico.css"
Vue.use(Antd);
Vue.use(ElementUI);
Vue.config.productionTip = false
import {
  get,
  post
} from './utils/ajax'
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "b8380d15c49c92a10ba767b1fa29d72f", // 高德地图 key
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation",
    "AMap.Geocoder",
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.10",
});


 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')